<script>
import { routerParams } from "@/libs/utils/routerParams";
import Model from "@/views/front/scds/homepage/components/model.vue";
import Layout from "@/views/front/jsf35/subsystems/layout";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import ModelInfoCard from "@/views/front/jsf35/subsystems/layout/components/ModelInfoCard.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import router from "@/router";

export default {
    name: "ScdsSubsystem",
    components: {
        ModelInfoCard,
        AppCollapse,
        AppCollapseItem,
        Layout,
        Model,
        VuePerfectScrollbar,
    },
    data() {
        return {
            system_key: routerParams("system_key"),
            sub_system_list: [
                { key: "kttf", name: "空调通风", icon: "wind" },
                { key: "zlxt", name: "冷源系统", icon: "snow2" },
                { key: "grxt", name: "热源系统", icon: "hdd-fill" },
                { key: "jps", name: "给排水", icon: "droplet" },
                { key: "bpd", name: "变配电", icon: "lightning-fill" },
                { key: "dtxt", name: "电梯系统", icon: "box-arrow-up" },
                { key: "ggzm", name: "公共照明", icon: "lightbulb-fill" },
                { key: "yjzm", name: "应急照明", icon: "tropical-storm" },
                { key: "hjjc", name: "环境监测", icon: "thermometer-half" },
                { key: "xfxt", name: "消防系统", icon: "shield-fill-exclamation" },
                { key: "xfxt", name: "安防系统", icon: "shield-fill-exclamation" },
                { key: "xfxt", name: "隐蔽工程", icon: "bezier" },
            ],
            sub_system_hover: "",
            add_system_list:[
              {key:'/e8480b59',name:"能源管理",type:"panel-group",icon:"lightning-charge"},
              {key:'/970eed9d',name:"碳排管理",type:"panel-group",icon:"arrow-repeat"},
              {key:'gczx',name:"工程中心",type: "",icon:"wrench"},
              {key:'yyzx',name:"运营中心",type: "",icon:"x-diamond-fill"},
              {key:'abzx',name:"安保中心",type: "",icon:"shield-check"},
              {key:'fwzx',name:"服务中心",type: "",icon:"person-fill"},
              {key:'/ledger-list',type: "",name:"资产管理",icon:"wallet"},
            ],
            electrical_floor: [],
            floorsList: [
                {
                    title: "RF",
                    value: 31,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F30",
                    value: 30,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F29",
                    value: 29,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F28",
                    value: 28,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F27",
                    value: 27,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F26",
                    value: 26,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F25",
                    value: 25,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F24",
                    value: 24,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F23",
                    value: 23,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F22",
                    value: 22,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F21",
                    value: 21,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F20",
                    value: 20,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F19",
                    value: 19,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F18",
                    value: 18,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F17",
                    value: 17,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F16",
                    value: 16,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F15",
                    value: 15,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F14",
                    value: 14,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F13",
                    value: 13,
                    model: {
                        path: "power_distribution/power_distribution_RF/power_distribution_RF",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F12",
                    value: 12,
                    model: {
                        path: "power_distribution/power_distribution_F11/power_distribution_F11",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
                    },
                },
                {
                    title: "F11",
                    value: 11,
                    model: {
                        path: "power_distribution/power_distribution_F10/power_distribution_F10",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF10", "LineF10", "Plane010"],
                    },
                },
                {
                    title: "F10",
                    value: 10,
                    model: {
                        path: "power_distribution/power_distribution_F9/power_distribution_F9",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF9", "LineF9", "Plane009"],
                    },
                },
                {
                    title: "F9",
                    value: 9,
                    model: {
                        path: "power_distribution/power_distribution_F8/power_distribution_F8",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF8", "LineF8", "Plane008"],
                    },
                },
                {
                    title: "F8",
                    value: 8,
                    model: {
                        path: "power_distribution/power_distribution_F7/power_distribution_F7",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF7", "equipment_AHUF7", "LineF7", "Plane007"],
                    },
                },
                {
                    title: "F7",
                    value: 7,
                    model: {
                        path: "power_distribution/power_distribution_F6/power_distribution_F6",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF6", "LineF6", "Plane006"],
                    },
                },
                {
                    title: "F6",
                    value: 6,
                    model: {
                        path: "power_distribution/power_distribution_F5/power_distribution_F5",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF5", "equipment_AHUF5", "LineF5", "Plane005"],
                    },
                },
                {
                    title: "F5",
                    value: 5,
                    model: {
                        path: "power_distribution/power_distribution_F4/power_distribution_F4",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF4", "LineF4", "Plane004"],
                    },
                },
                {
                    title: "F4",
                    value: 4,
                    model: {
                        path: "power_distribution/power_distribution_F4/power_distribution_F4",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF4", "LineF4", "Plane004"],
                    },
                },
                {
                    title: "F3",
                    value: 3,
                    model: {
                        path: "power_distribution/power_distribution_F3/power_distribution_F3",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF3", "LineF3", "Plane003"],
                    },
                },
                {
                    title: "F2",
                    value: 2,
                    model: {
                        path: "power_distribution/power_distribution_F2/power_distribution_F2",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF2", "equipment_AHUF2", "LineF2", "Plane002"],
                    },
                },
                {
                    title: "F1",
                    value: 1,
                    model: {
                        path: "power_distribution/power_distribution_F1/power_distribution_F1",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXF01", "LineF01", "Plane001"],
                    },
                },
                {
                    title: "B1",
                    value: -1,
                    model: {
                        path: "power_distribution/power_distribution_B1/power_distribution_B1",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXB1", "equipment_PFB1", "equipment_SFB1", "jsf_B1_Wall", "jsf_B1_floor"],
                    },
                },
                {
                    title: "B2",
                    value: -2,
                    model: {
                        path: "power_distribution/power_distribution_B2/power_distribution_B2",
                        dbIds: ["equipment_"],
                        // showDbIds: ["equipment_AHUXB2", "equipment_AHUB2", "equipment_BFB2", "equipment_PFB2", "equipment_SFB2", "equipment_YDB2", "jsf_B2_wall", "jsf_B2_floor"],
                    },
                },
            ],
            system_tree: [
                {
                    title: "排风机",
                    children: [
                        {
                            title: "B1",
                            children: [
                                {
                                    equip_id: 39,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "PF-B1-1自行车库排风",
                                    equip_key: "equipment_PF-B1-1",
                                    equip_meter_code: "JSF_KTTF_B1_N_PF-B1-1",
                                    ip_address: null,
                                    floor: "B1",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "排风机",
                                    group_lvl_2: "B1",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "PF-B1-1-故障报警",
                                            param_code: "EF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B1_N_PF-B1-1_EF-F",
                                            tag_name: "PF-B1-1-EF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PF-B1-1-启/停控制",
                                            param_code: "EF-C",
                                            unit: "启动||停止",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B1_N_PF-B1-1_EF-C",
                                            tag_name: "PF-B1-1-EF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "PF-B1-1-手/自动状态",
                                            param_code: "EF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B1_N_PF-B1-1_EF-AM",
                                            tag_name: "PF-B1-1-EF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PF-B1-1-运行状态",
                                            param_code: "EF-S",
                                            unit: "停机||运行",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B1_N_PF-B1-1_EF-S",
                                            tag_name: "PF-B1-1-EF-S",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PF-B1-1-CO-浓度设定",
                                            param_code: "CO-TSP",
                                            unit: "ppm",
                                            cur_value: null,
                                            time: null,
                                            meter_code: "JSF_KTTF_B1_N_PF-B1-1_CO-TSP",
                                            tag_name: "PF-B1-1-CO-TSP",
                                            is_control: 1,
                                        },
                                    ],
                                    title: "PF-B1-1自行车库排风",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                        {
                            title: "B2",
                            children: [
                                {
                                    equip_id: 61,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "PF-B2-8发电机房送风",
                                    equip_key: "equipment_PF-B2-8",
                                    equip_meter_code: "JSF_KTTF_B2_N_PF-B2-8",
                                    floor: "B2",
                                    group_lvl_1: "排风机",
                                    group_lvl_2: "B2",
                                    group_lvl_3: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "PF-B2-8-运行状态",
                                            param_code: "EF-S",
                                            unit: "停机||运行",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_N_PF-B2-8_EF-S",
                                            tag_name: "PF-B2-8-EF-S",
                                            is_control: null,
                                        },
                                    ],
                                    title: "PF-B2-8发电机房送风",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: [],
                                    },
                                },
                                {
                                    equip_id: 65,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "PF-B2-3生活水泵房排风",
                                    equip_key: "equipment_PF-B2-3",
                                    equip_meter_code: "JSF_KTTF_B2_N_PF-B2-3",
                                    ip_address: null,
                                    floor: "B2",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "排风机",
                                    group_lvl_2: "B2",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "PF-B2-3-故障报警",
                                            param_code: "EF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_B2_N_PF-B2-3_EF-F",
                                            tag_name: "PF-B2-3-EF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PF-B2-3-启/停控制",
                                            param_code: "EF-C",
                                            unit: "启动||停止",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_B2_N_PF-B2-3_EF-C",
                                            tag_name: "PF-B2-3-EF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "PF-B2-3-手/自动状态",
                                            param_code: "EF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_B2_N_PF-B2-3_EF-AM",
                                            tag_name: "PF-B2-3-EF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PF-B2-3-运行状态",
                                            param_code: "EF-S",
                                            unit: "停机||运行",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_B2_N_PF-B2-3_EF-S",
                                            tag_name: "PF-B2-3-EF-S",
                                            is_control: null,
                                        },
                                    ],
                                    title: "PF-B2-3生活水泵房排风",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "送风机",
                    children: [
                        {
                            title: "B1",
                            children: [
                                {
                                    equip_id: 41,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "SF-B1-1",
                                    equip_key: "equipment_SF-B1-1",
                                    equip_meter_code: "JSF_KTTF_B1_N_SF-B1-1",
                                    ip_address: null,
                                    floor: "B1",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "送风机",
                                    group_lvl_2: "B1",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "SF-B1-1-故障报警",
                                            param_code: "SF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B1_N_SF-B1-1_SF-F",
                                            tag_name: "SF-B1-1-SF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "SF-B1-1-启/停控制",
                                            param_code: "SF-C",
                                            unit: "启动||停止",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B1_N_SF-B1-1_SF-C",
                                            tag_name: "SF-B1-1-SF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "SF-B1-1-手/自动状态",
                                            param_code: "SF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B1_N_SF-B1-1_SF-AM",
                                            tag_name: "SF-B1-1-SF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "SF-B1-1-运行状态",
                                            param_code: "SF-S",
                                            unit: "停机||运行",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B1_N_SF-B1-1_SF-S",
                                            tag_name: "SF-B1-1-SF-S",
                                            is_control: null,
                                        },
                                    ],
                                    title: "SF-B1-1",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                        {
                            title: "B2",
                            children: [
                                {
                                    equip_id: 68,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "SF-B2-1车场送风",
                                    equip_key: "equipment_SF-B2-1",
                                    equip_meter_code: "JSF_KTTF_B2_A_SF-B2-1",
                                    ip_address: null,
                                    floor: "B2",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "送风机",
                                    group_lvl_2: "B2",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "SF-B2-1-故障报警",
                                            param_code: "SF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_A_SF-B2-1_SF-F",
                                            tag_name: "SF-B2-1-SF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "SF-B2-1-手/自动状态",
                                            param_code: "SF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_A_SF-B2-1_SF-AM",
                                            tag_name: "SF-B2-1-SF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "SF-B2-1-运行状态",
                                            param_code: "SF-S",
                                            unit: "停机||运行",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_A_SF-B2-1_SF-S",
                                            tag_name: "SF-B2-1-SF-S",
                                            is_control: null,
                                        },
                                    ],
                                    title: "SF-B2-1车场送风",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "新风机",
                    children: [
                        {
                            title: "F10",
                            children: [
                                {
                                    equip_id: 163,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "AHU(X)-F9-1北楼新风",
                                    equip_key: "equipment_AHUX-F9-1",
                                    equip_meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1",
                                    ip_address: null,
                                    floor: "F10",
                                    detail_model_key: "Detail/fresh-air/fresh-air",
                                    model_dbids: null,
                                    model_rotate: "-387,-1025,28",
                                    model_equip_status_config: null,
                                    group_lvl_1: "新风机",
                                    group_lvl_2: "F10",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "AHU(X)-F9-1-风机故障报警",
                                            param_code: "SF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_SF-F",
                                            tag_name: "AHU(X)-F9-1-SF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-风机启/停控制",
                                            param_code: "SF-C",
                                            unit: "启动||停止",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_SF-C",
                                            tag_name: "AHU(X)-F9-1-SF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-风机手/自动状态",
                                            param_code: "SF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_SF-AM",
                                            tag_name: "AHU(X)-F9-1-SF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-风机压差状态",
                                            param_code: "SF-SP",
                                            unit: "关闭||开启",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_SF-SP",
                                            tag_name: "AHU(X)-F9-1-SF-SP",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-风机运行状态",
                                            param_code: "SF-S",
                                            unit: "停机||运行",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_SF-S",
                                            tag_name: "AHU(X)-F9-1-SF-S",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-初效过滤报警",
                                            param_code: "PRFILT-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_PRFILT-A",
                                            tag_name: "AHU(X)-F9-1-PRFILT-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-防冻报警",
                                            param_code: "LOW-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_LOW-A",
                                            tag_name: "AHU(X)-F9-1-LOW-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-防冻阀开关控制",
                                            param_code: "PAD-C",
                                            unit: null,
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_PAD-C",
                                            tag_name: "AHU(X)-F9-1-PAD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-加湿控制",
                                            param_code: "HUM-C",
                                            unit: null,
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_HUM-C",
                                            tag_name: "AHU(X)-F9-1-HUM-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-冷热水阀调节",
                                            param_code: "CV-C",
                                            unit: null,
                                            cur_value: 4.97,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_CV-C",
                                            tag_name: "AHU(X)-F9-1-CV-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-送风湿度",
                                            param_code: "SF-H",
                                            unit: "%",
                                            cur_value: 57.28,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_SF-H",
                                            tag_name: "AHU(X)-F9-1-SF-H",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-送风温度",
                                            param_code: "SF-T",
                                            unit: "℃",
                                            cur_value: 23.91,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_SF-T",
                                            tag_name: "AHU(X)-F9-1-SF-T",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-新风阀调节",
                                            param_code: "OAD-C",
                                            unit: null,
                                            cur_value: 60,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_OAD-C",
                                            tag_name: "AHU(X)-F9-1-OAD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-中效过滤报警",
                                            param_code: "SFILT-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_SFILT-A",
                                            tag_name: "AHU(X)-F9-1-SFILT-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-温度设定",
                                            param_code: "SF-TSP",
                                            unit: "°C",
                                            cur_value: 40,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_SF-TSP",
                                            tag_name: "AHU(X)-F9-1-SF-TSP",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F9-1-CO2浓度设定",
                                            param_code: "CO2-TSP",
                                            unit: "ppm",
                                            cur_value: 800,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F10_N_AHU(X)-F9-1_CO2-TSP",
                                            tag_name: "AHU(X)-F9-1-CO2-TSP",
                                            is_control: 1,
                                        },
                                    ],
                                    title: "AHU(X)-F9-1北楼新风",
                                    model: {
                                        path: "Detail/fresh-air/fresh-air",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                        {
                            title: "F11",
                            children: [
                                {
                                    equip_id: 167,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "AHU(X)-F10-1北楼新风",
                                    equip_key: "equipment_AHUX-F10-1",
                                    equip_meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1",
                                    ip_address: null,
                                    floor: "F11",
                                    detail_model_key: "Detail/fresh-air/fresh-air",
                                    model_dbids: null,
                                    model_rotate: "-387,-1025,28",
                                    model_equip_status_config: null,
                                    group_lvl_1: "新风机",
                                    group_lvl_2: "F11",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "AHU(X)-F10-1-风机故障报警",
                                            param_code: "SF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_SF-F",
                                            tag_name: "AHU(X)-F10-1-SF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-风机启/停控制",
                                            param_code: "SF-C",
                                            unit: "启动||停止",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_SF-C",
                                            tag_name: "AHU(X)-F10-1-SF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-风机手/自动状态",
                                            param_code: "SF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_SF-AM",
                                            tag_name: "AHU(X)-F10-1-SF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-风机压差状态",
                                            param_code: "SF-SP",
                                            unit: "关闭||开启",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_SF-SP",
                                            tag_name: "AHU(X)-F10-1-SF-SP",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-风机运行状态",
                                            param_code: "SF-S",
                                            unit: "停机||运行",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_SF-S",
                                            tag_name: "AHU(X)-F10-1-SF-S",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-初效过滤报警",
                                            param_code: "PRFILT-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_PRFILT-A",
                                            tag_name: "AHU(X)-F10-1-PRFILT-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-防冻报警",
                                            param_code: "LOW-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_LOW-A",
                                            tag_name: "AHU(X)-F10-1-LOW-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-防冻阀开关控制",
                                            param_code: "PAD-C",
                                            unit: null,
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_PAD-C",
                                            tag_name: "AHU(X)-F10-1-PAD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-加湿控制",
                                            param_code: "HUM-C",
                                            unit: null,
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_HUM-C",
                                            tag_name: "AHU(X)-F10-1-HUM-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-冷热水阀调节",
                                            param_code: "CV-C",
                                            unit: null,
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_CV-C",
                                            tag_name: "AHU(X)-F10-1-CV-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-送风湿度",
                                            param_code: "SF-H",
                                            unit: "%",
                                            cur_value: 73.13,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_SF-H",
                                            tag_name: "AHU(X)-F10-1-SF-H",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-送风温度",
                                            param_code: "SF-T",
                                            unit: "℃",
                                            cur_value: 15.79,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_SF-T",
                                            tag_name: "AHU(X)-F10-1-SF-T",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-新风阀调节",
                                            param_code: "OAD-C",
                                            unit: null,
                                            cur_value: 60,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_OAD-C",
                                            tag_name: "AHU(X)-F10-1-OAD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-中效过滤报警",
                                            param_code: "SFILT-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_SFILT-A",
                                            tag_name: "AHU(X)-F10-1-SFILT-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-温度设定",
                                            param_code: "SF-TSP",
                                            unit: "°C",
                                            cur_value: 24,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_SF-TSP",
                                            tag_name: "AHU(X)-F10-1-SF-TSP",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-CO2浓度设定",
                                            param_code: "CO2-TSP",
                                            unit: "ppm",
                                            cur_value: 800,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_CO2-TSP",
                                            tag_name: "AHU(X)-F10-1-CO2-TSP",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F10-1-送风湿度设定",
                                            param_code: "SF-HSP",
                                            unit: "%",
                                            cur_value: 40,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F11_N_AHU(X)-F10-1_SF-HSP",
                                            tag_name: "AHU(X)-F10-1-SF-HSP",
                                            is_control: 1,
                                        },
                                    ],
                                    title: "AHU(X)-F10-1北楼新风",
                                    model: {
                                        path: "Detail/fresh-air/fresh-air",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                        {
                            title: "F12",
                            children: [
                                {
                                    equip_id: 171,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "AHU(X)-F11-1北楼新风",
                                    equip_key: "equipment_AHUX-F11-1",
                                    equip_meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1",
                                    ip_address: null,
                                    floor: "F12",
                                    detail_model_key: "Detail/fresh-air/fresh-air",
                                    model_dbids: null,
                                    model_rotate: "-387,-1025,28",
                                    model_equip_status_config: null,
                                    group_lvl_1: "新风机",
                                    group_lvl_2: "F12",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "AHU(X)-F11-1-风机故障报警",
                                            param_code: "SF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_SF-F",
                                            tag_name: "AHU(X)-F11-1-SF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-风机启/停控制",
                                            param_code: "SF-C",
                                            unit: "启动||停止",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_SF-C",
                                            tag_name: "AHU(X)-F11-1-SF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-风机手/自动状态",
                                            param_code: "SF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_SF-AM",
                                            tag_name: "AHU(X)-F11-1-SF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-风机压差状态",
                                            param_code: "SF-SP",
                                            unit: "关闭||开启",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_SF-SP",
                                            tag_name: "AHU(X)-F11-1-SF-SP",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-风机运行状态",
                                            param_code: "SF-S",
                                            unit: "停机||运行",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_SF-S",
                                            tag_name: "AHU(X)-F11-1-SF-S",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-初效过滤报警",
                                            param_code: "PRFILT-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_PRFILT-A",
                                            tag_name: "AHU(X)-F11-1-PRFILT-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-防冻报警",
                                            param_code: "LOW-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_LOW-A",
                                            tag_name: "AHU(X)-F11-1-LOW-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-防冻阀开关控制",
                                            param_code: "PAD-C",
                                            unit: null,
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_PAD-C",
                                            tag_name: "AHU(X)-F11-1-PAD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-加湿控制",
                                            param_code: "HUM-C",
                                            unit: null,
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_HUM-C",
                                            tag_name: "AHU(X)-F11-1-HUM-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-冷热水阀调节",
                                            param_code: "CV-C",
                                            unit: null,
                                            cur_value: 2.64,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_CV-C",
                                            tag_name: "AHU(X)-F11-1-CV-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-送风湿度",
                                            param_code: "SF-H",
                                            unit: "%",
                                            cur_value: 58.01,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_SF-H",
                                            tag_name: "AHU(X)-F11-1-SF-H",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-送风温度",
                                            param_code: "SF-T",
                                            unit: "℃",
                                            cur_value: 23.67,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_SF-T",
                                            tag_name: "AHU(X)-F11-1-SF-T",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-新风阀调节",
                                            param_code: "OAD-C",
                                            unit: null,
                                            cur_value: 60,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_OAD-C",
                                            tag_name: "AHU(X)-F11-1-OAD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-中效过滤报警",
                                            param_code: "SFILT-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_SFILT-A",
                                            tag_name: "AHU(X)-F11-1-SFILT-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-温度设定",
                                            param_code: "SF-TSP",
                                            unit: "°C",
                                            cur_value: 24,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_SF-TSP",
                                            tag_name: "AHU(X)-F11-1-SF-TSP",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-CO2浓度设定",
                                            param_code: "CO2-TSP",
                                            unit: "ppm",
                                            cur_value: 800,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_CO2-TSP",
                                            tag_name: "AHU(X)-F11-1-CO2-TSP",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU(X)-F11-1-送风湿度设定",
                                            param_code: "SF-HSP",
                                            unit: "%",
                                            cur_value: 40,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_F12_N_AHU(X)-F11-1_SF-HSP",
                                            tag_name: "AHU(X)-F11-1-SF-HSP",
                                            is_control: 1,
                                        },
                                    ],
                                    title: "AHU(X)-F11-1北楼新风",
                                    model: {
                                        path: "Detail/fresh-air/fresh-air",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "补风机",
                    children: [
                        {
                            title: "B2",
                            children: [
                                {
                                    equip_id: 59,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "BF-B2-1物业门口隔油池门口送风",
                                    equip_key: "equipment_BF-B2-1",
                                    equip_meter_code: "JSF_KTTF_B2_A_BF-B2-1",
                                    ip_address: null,
                                    floor: "B2",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "补风机",
                                    group_lvl_2: "B2",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "BF-B2-1-故障报警",
                                            param_code: "F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_A_BF-B2-1_F",
                                            tag_name: "BF-B2-1-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "BF-B2-1-启/停控制",
                                            param_code: "C",
                                            unit: "启动||停止",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_A_BF-B2-1_C",
                                            tag_name: "BF-B2-1-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "BF-B2-1-手/自动状态",
                                            param_code: "AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_A_BF-B2-1_AM",
                                            tag_name: "BF-B2-1-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "BF-B2-1-运行状态",
                                            param_code: "S",
                                            unit: "停机||运行",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_A_BF-B2-1_S",
                                            tag_name: "BF-B2-1-S",
                                            is_control: null,
                                        },
                                    ],
                                    title: "BF-B2-1物业门口隔油池门口送风",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                        {
                            title: "B3",
                            children: [
                                {
                                    equip_id: 96,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "BF-B3-4-1车场送风",
                                    equip_key: "equipment_BF-B3-4-1",
                                    equip_meter_code: "JSF_KTTF_B3_A_BF-B3-4-1",
                                    ip_address: null,
                                    floor: "B3",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "补风机",
                                    group_lvl_2: "B3",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "BF-B3-4-1-故障报警",
                                            param_code: "EF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_A_BF-B3-4-1_EF-F",
                                            tag_name: "BF-B3-4-1-EF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "BF-B3-4-1-手/自动状态",
                                            param_code: "EF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_A_BF-B3-4-1_EF-AM",
                                            tag_name: "BF-B3-4-1-EF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "BF-B3-4-1-运行状态",
                                            param_code: "EF-S",
                                            unit: "停机||运行",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_A_BF-B3-4-1_EF-S",
                                            tag_name: "BF-B3-4-1-EF-S",
                                            is_control: null,
                                        },
                                    ],
                                    title: "BF-B3-4-1车场送风",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                        {
                            title: "B5",
                            children: [
                                {
                                    equip_id: 107,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "BF-B4-2-1南楼车场送风",
                                    equip_key: "equipment_BF-B4-2-1",
                                    equip_meter_code: "JSF_KTTF_B5_N_BF-B4-2-1",
                                    ip_address: null,
                                    floor: "B5",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "补风机",
                                    group_lvl_2: "B5",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "BF-B4-2-1-启/停控制",
                                            param_code: "EF-F",
                                            unit: "启动||停止",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B5_N_BF-B4-2-1_EF-F",
                                            tag_name: "BF-B4-2-1-EF-F",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "BF-B4-2-1-手/自动状态",
                                            param_code: "EF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B5_N_BF-B4-2-1_EF-AM",
                                            tag_name: "BF-B4-2-1-EF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "BF-B4-2-1-运行状态",
                                            param_code: "EF-S",
                                            unit: "停机||运行",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B5_N_BF-B4-2-1_EF-S",
                                            tag_name: "BF-B4-2-1-EF-S",
                                            is_control: null,
                                        },
                                    ],
                                    title: "BF-B4-2-1南楼车场送风",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "排烟风机",
                    children: [
                        {
                            title: "B2",
                            children: [
                                {
                                    equip_id: 66,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "PY-B2-1",
                                    equip_key: "equipment_PF-B2-1",
                                    equip_meter_code: "JSF_KTTF_B2_N_PY-B2-1",
                                    ip_address: null,
                                    floor: "B2",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "排烟风机",
                                    group_lvl_2: "B2",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "PY-B2-1-2-风机启/停控制",
                                            param_code: "EF-C",
                                            unit: "启动||停止",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_N_PY-B2-1_EF-C",
                                            tag_name: "PY-B2-1-EF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "PY-B2-1-故障报警",
                                            param_code: "EF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_N_PY-B2-1_EF-F",
                                            tag_name: "PY-B2-1-EF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PY-B2-1-手/自动状态",
                                            param_code: "EF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_N_PY-B2-1_EF-AM",
                                            tag_name: "PY-B2-1-EF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PY-B2-1-运行状态",
                                            param_code: "EF-S",
                                            unit: "停机||运行",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B2_N_PY-B2-1_EF-S",
                                            tag_name: "PY-B2-1-EF-S",
                                            is_control: null,
                                        },
                                    ],
                                    title: "PY-B2-1",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                        {
                            title: "B3",
                            children: [
                                {
                                    equip_id: 92,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "PY-B3-1-1",
                                    equip_key: "equipment_PF-B3-1-1",
                                    equip_meter_code: "JSF_KTTF_B3_N_PY-B3-1-1",
                                    ip_address: null,
                                    floor: "B3",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "排烟风机",
                                    group_lvl_2: "B3",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "PY-B3-1-1-故障报警",
                                            param_code: "EF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_N_PY-B3-1-1_EF-F",
                                            tag_name: "PY-B3-1-1-EF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PY-B3-1-1-启/停控制",
                                            param_code: "EF-C",
                                            unit: "启动||停止",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_N_PY-B3-1-1_EF-C",
                                            tag_name: "PY-B3-1-1-EF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "PY-B3-1-1-手/自动状态",
                                            param_code: "EF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_N_PY-B3-1-1_EF-AM",
                                            tag_name: "PY-B3-1-1-EF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PY-B3-1-1-运行状态",
                                            param_code: "EF-S",
                                            unit: "停机||运行",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_N_PY-B3-1-1_EF-S",
                                            tag_name: "PY-B3-1-1-EF-S",
                                            is_control: null,
                                        },
                                    ],
                                    title: "PY-B3-1-1",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                        {
                            title: "B5",
                            children: [
                                {
                                    equip_id: 101,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "PY-B4-1-1",
                                    equip_key: "equipment_PF-B4-1-1",
                                    equip_meter_code: "JSF_KTTF_B5_N_PY-B4-1-1",
                                    ip_address: null,
                                    floor: "B5",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "排烟风机",
                                    group_lvl_2: "B5",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "PY-B4-1-1-故障报警",
                                            param_code: "EF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B5_N_PY-B4-1-1_EF-F",
                                            tag_name: "PY-B4-1-1-EF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PY-B4-1-1-启/停控制",
                                            param_code: "EF-C",
                                            unit: "启动||停止",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B5_N_PY-B4-1-1_EF-C",
                                            tag_name: "PY-B4-1-1-EF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "PY-B4-1-1-手/自动状态",
                                            param_code: "EF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B5_N_PY-B4-1-1_EF-AM",
                                            tag_name: "PY-B4-1-1-EF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "PY-B4-1-1-运行状态",
                                            param_code: "EF-S",
                                            unit: "停机||运行",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B5_N_PY-B4-1-1_EF-S",
                                            tag_name: "PY-B4-1-1-EF-S",
                                            is_control: null,
                                        },
                                    ],
                                    title: "PY-B4-1-1",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "诱导风机",
                    children: [
                        {
                            title: "B2",
                            children: [
                                {
                                    equip_id: 83,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "YD-B2-13",
                                    equip_key: "equipment_YD-B2-13",
                                    equip_meter_code: null,
                                    ip_address: null,
                                    floor: "B2",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "诱导风机",
                                    group_lvl_2: "B2",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [],
                                    title: "YD-B2-13",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                        {
                            title: "B3",
                            children: [
                                {
                                    equip_id: 98,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "YD-B3-11",
                                    equip_key: "equipment_YD-B3-11",
                                    equip_meter_code: null,
                                    ip_address: null,
                                    floor: "B3",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "诱导风机",
                                    group_lvl_2: "B3",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [],
                                    title: "YD-B3-11",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                        {
                            title: "B5",
                            children: [
                                {
                                    equip_id: 115,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "YD-B5-1",
                                    equip_key: "equipment_YD-B4-1",
                                    equip_meter_code: null,
                                    ip_address: null,
                                    floor: "B5",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "诱导风机",
                                    group_lvl_2: "B5",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [],
                                    title: "YD-B5-1",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                                {
                                    equip_id: 116,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "YD-B5-2",
                                    equip_key: "equipment_YD-B4-2",
                                    equip_meter_code: null,
                                    ip_address: null,
                                    floor: "B5",
                                    detail_model_key: "Detail/air-pipe/air-pipe",
                                    model_dbids: null,
                                    model_rotate: "310,837,-19",
                                    model_equip_status_config: null,
                                    group_lvl_1: "诱导风机",
                                    group_lvl_2: "B5",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [],
                                    title: "YD-B5-2",
                                    model: {
                                        path: "Detail/air-pipe/air-pipe",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "空调机",
                    children: [
                        {
                            title: "B3",
                            children: [
                                {
                                    equip_id: 100,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "AHU-B3-7配电室送风",
                                    equip_key: "equipment_AHU-B3-7",
                                    equip_meter_code: "JSF_KTTF_B3_S_AHU-B3-7",
                                    ip_address: null,
                                    floor: "B3",
                                    detail_model_key: "Detail/air-conditioner/air-conditioner",
                                    model_dbids: null,
                                    model_rotate: "-445,-1864,106",
                                    model_equip_status_config: null,
                                    group_lvl_1: "空调机",
                                    group_lvl_2: "B3",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "AHU-B3-7-初效过滤报警",
                                            param_code: "PRFILT-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_PRFILT-A",
                                            tag_name: "AHU-B3-7-PRFILT-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-防冻报警",
                                            param_code: "LOW-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_LOW-A",
                                            tag_name: "AHU-B3-7-LOW-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-防冻阀开关控制",
                                            param_code: "PAD-C",
                                            unit: null,
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_PAD-C",
                                            tag_name: "AHU-B3-7-PAD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU-B3-7-回风湿度",
                                            param_code: "RA-H",
                                            unit: "%",
                                            cur_value: null,
                                            time: null,
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_RA-H",
                                            tag_name: "AHU-B3-7-RA-H",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-回风温度",
                                            param_code: "RA-T",
                                            unit: "℃",
                                            cur_value: null,
                                            time: null,
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_RA-T",
                                            tag_name: "AHU-B3-7-RA-T",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-加湿控制",
                                            param_code: "HUM-C",
                                            unit: null,
                                            cur_value: null,
                                            time: null,
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_HUM-C",
                                            tag_name: "AHU-B3-7-HUM-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU-B3-7-冷热水阀调节",
                                            param_code: "VLV-C",
                                            unit: null,
                                            cur_value: 100,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_VLV-C",
                                            tag_name: "AHU-B3-7-VLV-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU-B3-7-送风湿度",
                                            param_code: "SF-H",
                                            unit: "%",
                                            cur_value: 49.3,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_SF-H",
                                            tag_name: "AHU-B3-7-SF-H",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-送风温度",
                                            param_code: "SF-T",
                                            unit: "℃",
                                            cur_value: 29.3,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_SF-T",
                                            tag_name: "AHU-B3-7-SF-T",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-中效过滤报警",
                                            param_code: "SFILT-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_SFILT-A",
                                            tag_name: "AHU-B3-7-SFILT-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-排风机故障报警",
                                            param_code: "EF-F",
                                            unit: "正常||故障",
                                            cur_value: null,
                                            time: null,
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_EF-F",
                                            tag_name: "AHU-B3-7-EF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-排风机启/停控制",
                                            param_code: "EF-C",
                                            unit: "启动||停止",
                                            cur_value: null,
                                            time: null,
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_EF-C",
                                            tag_name: "AHU-B3-7-EF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU-B3-7-排风机手/自动状态",
                                            param_code: "EF-AM",
                                            unit: "手动||自动",
                                            cur_value: null,
                                            time: null,
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_EF-AM",
                                            tag_name: "AHU-B3-7-EF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-排风机压差状态",
                                            param_code: "EF-SP",
                                            unit: "关闭||开启",
                                            cur_value: null,
                                            time: null,
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_EF-SP",
                                            tag_name: "AHU-B3-7-EF-SP",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-排风机运行状态",
                                            param_code: "EF-S",
                                            unit: "停机||运行",
                                            cur_value: null,
                                            time: null,
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_EF-S",
                                            tag_name: "AHU-B3-7-EF-S",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-送风机故障报警",
                                            param_code: "SF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_SF-F",
                                            tag_name: "AHU-B3-7-SF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-送风机启/停控制",
                                            param_code: "SF-C",
                                            unit: "启动||停止",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_SF-C",
                                            tag_name: "AHU-B3-7-SF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU-B3-7-送风机手/自动状态",
                                            param_code: "SF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_SF-AM",
                                            tag_name: "AHU-B3-7-SF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-送风机压差状态",
                                            param_code: "SF-SP",
                                            unit: "关闭||开启",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_SF-SP",
                                            tag_name: "AHU-B3-7-SF-SP",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-送风机运行状态",
                                            param_code: "SF-S",
                                            unit: "停机||运行",
                                            cur_value: 1,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_SF-S",
                                            tag_name: "AHU-B3-7-SF-S",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "AHU-B3-7-回风阀调节",
                                            param_code: "RAD-C",
                                            unit: null,
                                            cur_value: null,
                                            time: null,
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_RAD-C",
                                            tag_name: "AHU-B3-7-RAD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "AHU-B3-7-新风阀调节",
                                            param_code: "OAD-C",
                                            unit: null,
                                            cur_value: 100,
                                            time: "2024-06-11 14:17:36",
                                            meter_code: "JSF_KTTF_B3_S_AHU-B3-7_OAD-C",
                                            tag_name: "AHU-B3-7-OAD-C",
                                            is_control: 1,
                                        },
                                    ],
                                    title: "AHU-B3-7配电室送风",
                                    model: {
                                        path: "Detail/air-conditioner/air-conditioner",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "热回收机组",
                    children: [
                        {
                            title: "RF",
                            children: [
                                {
                                    equip_id: 176,
                                    system_type: "air-conditioning-ventilation",
                                    equip_name: "RHS-1-1（北楼热回收机组）",
                                    equip_key: "equipment_RHS-1-1",
                                    equip_meter_code: "JSF_KTTF_RF_A_RHS-1-1",
                                    ip_address: null,
                                    floor: "RF",
                                    detail_model_key: "Detail/heat-recovery/heat-recovery",
                                    model_dbids: null,
                                    model_rotate: "-445,-1864,106",
                                    model_equip_status_config: null,
                                    group_lvl_1: "热回收机组",
                                    group_lvl_2: "RF",
                                    group_lvl_3: null,
                                    location: null,
                                    image: null,
                                    project_id: 72,
                                    error_handle: null,
                                    params: [
                                        {
                                            param_name: "RHS-1-1-排风阀调节",
                                            param_code: "EAD-C",
                                            unit: null,
                                            cur_value: 100,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_EAD-C",
                                            tag_name: "RHS-1-1-EAD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "RHS-1-1-排风机手/自动状态",
                                            param_code: "EF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_EF-AM",
                                            tag_name: "RHS-1-1-EF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-排风机启/停控制",
                                            param_code: "EF-C",
                                            unit: "启动||停止",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_EF-C",
                                            tag_name: "RHS-1-1-EF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "RHS-1-1-排风机故障报警",
                                            param_code: "EF-F",
                                            unit: "正常||故障",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_EF-F",
                                            tag_name: "RHS-1-1-EF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-排风初效过滤报警",
                                            param_code: "EFPRFILT-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_EFPRFILT-A",
                                            tag_name: "RHS-1-1-EFPRFILT-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-排风机运行状态",
                                            param_code: "EF-S",
                                            unit: "停机||运行",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_EF-S",
                                            tag_name: "RHS-1-1-EF-S",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-排风机变频控制",
                                            param_code: "EFVSD-C",
                                            unit: "HZ",
                                            cur_value: 40,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_EFVSD-C",
                                            tag_name: "RHS-1-1-EFVSD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "RHS-1-1-排风机变频反馈",
                                            param_code: "EFVSD-FB",
                                            unit: "HZ",
                                            cur_value: -12.5,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_EFVSD-FB",
                                            tag_name: "RHS-1-1-EFVSD-FB",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-回风温度",
                                            param_code: "HF-T",
                                            unit: "℃",
                                            cur_value: 36.28,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_HF-T",
                                            tag_name: "RHS-1-1-HF-T",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-防冻报警",
                                            param_code: "LOW-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_LOW-A",
                                            tag_name: "RHS-1-1-LOW-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-新风阀调节",
                                            param_code: "OAD-C",
                                            unit: null,
                                            cur_value: 100,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_OAD-C",
                                            tag_name: "RHS-1-1-OAD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "RHS-1-1-排风温度",
                                            param_code: "PF-T",
                                            unit: "℃",
                                            cur_value: 26.12,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_PF-T",
                                            tag_name: "RHS-1-1-PF-T",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-转轮手/自动状态",
                                            param_code: "RZL-AM",
                                            unit: "手动||自动",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_RZL-AM",
                                            tag_name: "RHS-1-1-RZL-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-转轮启/停控制",
                                            param_code: "RZL-C",
                                            unit: "启动||停止",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_RZL-C",
                                            tag_name: "RHS-1-1-RZL-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "RHS-1-1-转轮故障报警",
                                            param_code: "RZL-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_RZL-F",
                                            tag_name: "RHS-1-1-RZL-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-转轮运行状态",
                                            param_code: "RZL-S",
                                            unit: "停机||运行",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_RZL-S",
                                            tag_name: "RHS-1-1-RZL-S",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-送风机手/自动状态",
                                            param_code: "SF-AM",
                                            unit: "手动||自动",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_SF-AM",
                                            tag_name: "RHS-1-1-SF-AM",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-送风机启/停控制",
                                            param_code: "SF-C",
                                            unit: "启动||停止",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_SF-C",
                                            tag_name: "RHS-1-1-SF-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "RHS-1-1-送风机故障报警",
                                            param_code: "SF-F",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_SF-F",
                                            tag_name: "RHS-1-1-SF-F",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-送风湿度",
                                            param_code: "SF-H",
                                            unit: "%",
                                            cur_value: 29.6,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_SF-H",
                                            tag_name: "RHS-1-1-SF-H",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-送风初效过滤报警",
                                            param_code: "SFPRFILT-A",
                                            unit: "正常||故障",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_SFPRFILT-A",
                                            tag_name: "RHS-1-1-SFPRFILT-A",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-送风机运行状态",
                                            param_code: "SF-S",
                                            unit: "停机||运行",
                                            cur_value: 1,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_SF-S",
                                            tag_name: "RHS-1-1-SF-S",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-送风机压差状态",
                                            param_code: "SF-SP",
                                            unit: "关闭||开启",
                                            cur_value: 0,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_SF-SP",
                                            tag_name: "RHS-1-1-SF-SP",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-送风温度",
                                            param_code: "SF-T",
                                            unit: "℃",
                                            cur_value: 36.54,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_SF-T",
                                            tag_name: "RHS-1-1-SF-T",
                                            is_control: null,
                                        },
                                        {
                                            param_name: "RHS-1-1-送风机变频控制",
                                            param_code: "SFVSD-C",
                                            unit: "HZ",
                                            cur_value: 40,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_SFVSD-C",
                                            tag_name: "RHS-1-1-SFVSD-C",
                                            is_control: 1,
                                        },
                                        {
                                            param_name: "RHS-1-1-送风机变频反馈",
                                            param_code: "SFVSD-FB",
                                            unit: "HZ",
                                            cur_value: -8.68,
                                            time: "2024-06-11 14:18:07",
                                            meter_code: "JSF_KTTF_RF_A_RHS-1-1_SFVSD-FB",
                                            tag_name: "RHS-1-1-SFVSD-FB",
                                            is_control: null,
                                        },
                                    ],
                                    title: "RHS-1-1（北楼热回收机组）",
                                    model: {
                                        path: "Detail/heat-recovery/heat-recovery",
                                        dbIds: null,
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
            modelInfo: {
                equip_id: 39,
                system_type: "air-conditioning-ventilation",
                equip_name: "PF-B1-1自行车库排风",
                equip_key: "equipment_PF-B1-1",
                equip_meter_code: "JSF_KTTF_B1_N_PF-B1-1",
                ip_address: null,
                floor: "B1",
                detail_model_key: "Detail/air-pipe/air-pipe",
                model_dbids: null,
                model_rotate: "310,837,-19",
                model_equip_status_config: null,
                group_lvl_1: "排风机",
                group_lvl_2: "B1",
                group_lvl_3: null,
                location: null,
                image: null,
                project_id: 72,
                error_handle: null,
                params: [
                    {
                        param_name: "PF-B1-1-故障报警",
                        param_code: "EF-F",
                        unit: "正常||故障",
                        cur_value: 0,
                        time: "2024-06-11 14:17:36",
                        meter_code: "JSF_KTTF_B1_N_PF-B1-1_EF-F",
                        tag_name: "PF-B1-1-EF-F",
                        is_control: null,
                    },
                    {
                        param_name: "PF-B1-1-启/停控制",
                        param_code: "EF-C",
                        unit: "启动||停止",
                        cur_value: 0,
                        time: "2024-06-11 14:17:36",
                        meter_code: "JSF_KTTF_B1_N_PF-B1-1_EF-C",
                        tag_name: "PF-B1-1-EF-C",
                        is_control: 1,
                    },
                    {
                        param_name: "PF-B1-1-手/自动状态",
                        param_code: "EF-AM",
                        unit: "手动||自动",
                        cur_value: 1,
                        time: "2024-06-11 14:17:36",
                        meter_code: "JSF_KTTF_B1_N_PF-B1-1_EF-AM",
                        tag_name: "PF-B1-1-EF-AM",
                        is_control: null,
                    },
                    {
                        param_name: "PF-B1-1-运行状态",
                        param_code: "EF-S",
                        unit: "停机||运行",
                        cur_value: 0,
                        time: "2024-06-11 14:17:36",
                        meter_code: "JSF_KTTF_B1_N_PF-B1-1_EF-S",
                        tag_name: "PF-B1-1-EF-S",
                        is_control: null,
                    },
                ],
                title: "PF-B1-1自行车库排风",
                model: {
                    path: "Detail/air-pipe/air-pipe",
                    dbIds: null,
                },
            },
            sidebarVisible: false,
        }
    },
    methods: {
        //显示侧边栏
        handleClick(item) {
            this.sidebarVisible = true;
            this.modelInfo = item;
        },
        //右侧按钮点击跳转页面
        jumpToUrl(item){
          if (item.type.includes("panel-group")){
            router.push({
              name:"panel-group",
              params:{
                uid: item.key
              }
            })
          }else {
            console.log("不是panel");
            router.push({
              name: item.key
            })
          }
        },
    },
    watch: {
        //监听子系统类型，
        system_key(val) {
            console.log("---system_key", val);
            if (!val || val === "all") {
                this.sidebarVisible = false;
            } else {
                this.sidebarVisible = true;
            }
        },
    },
    mounted() {
    },
};
</script>

<template>
    <section>
        <div class="w-100" style="height: calc(100vh - 6rem)">
            <Model :systemKey="system_key || 'all'" />
        </div>
        <!-- 右侧导航 -->
        <div class="position-absolute" style="left: 2rem; top: 6rem" @mouseleave="sub_system_hover = ''">
            <b-row>
                <b-col>
                    <b-button-group vertical>
                        <b-button stacked size="sm" v-for="(item, index) in sub_system_list" :key="index" :variant="system_key === item.key
                            ? 'relief-secondary'
                            : 'gradient-primary'" class="mb-50" @mouseover="sub_system_hover = item.key"
                            @click="system_key = item.key">
                            <div class="mb-50" style="font-size: 1.5rem"><b-icon :icon="item.icon" /></div>
                            {{ item.name }}
                        </b-button>
                    </b-button-group>
                </b-col>
                <b-col class="px-0" v-if="sub_system_hover">
                    <b-card no-body style="min-height: 600px; width: 260px;">
                        <b-card-body>
                            <b-card-title>{{
                                sub_system_list.find((item) => item.key === sub_system_hover)
                                    .name
                            }}</b-card-title>
                        </b-card-body>

                        <b-tabs fill>
                            <b-tab title="楼层">
                                <vue-perfect-scrollbar style="height: calc(100vh - 20rem)">
                                    <b-list-group flush>
                                        <b-list-group-item v-for="(floor, floorIndex) in this.floorsList"
                                            :key="floorIndex" @click="$emit('select', floor)" class="px-1">
                                            {{ floor.title }}
                                        </b-list-group-item>
                                    </b-list-group>
                                </vue-perfect-scrollbar>
                            </b-tab>
                            <b-tab title="系统">
                                <vue-perfect-scrollbar style="height: calc(100vh - 20rem)">
                                    <app-collapse v-if="
                                        system_tree[0] &&
                                        system_tree[0].children &&
                                        system_tree[0].children.length > 0
                                    " accordion class="collapse-menu">
                                        <app-collapse-item v-for="(lvl_1, lvl_1_index) in system_tree"
                                            :key="'lvl_1_' + lvl_1_index" :title="lvl_1.title">
                                            <template v-if="
                                                lvl_1.children[0].children &&
                                                lvl_1.children[0].children.length > 0
                                            ">
                                                <app-collapse accordion class="collapse-menu-lvl2">
                                                    <app-collapse-item v-for="(lvl_2, lvl_2_index) in lvl_1.children"
                                                        :key="'lvl_2_' + lvl_2_index" :title="lvl_2.title">
                                                        <b-list-group flush>
                                                            <b-list-group-item v-for="(item, index) in lvl_2.children"
                                                                :key="index" @click="handleClick(item)" class="px-1">
                                                                {{ item.title }}
                                                            </b-list-group-item>
                                                        </b-list-group>
                                                    </app-collapse-item>
                                                </app-collapse>
                                            </template>
                                            <template v-else>
                                                <b-list-group flush :list="lvl_1.children" @select="(event) => {
                                                    $emit('select', event);
                                                }
                                                    "></b-list-group>
                                            </template>
                                        </app-collapse-item>
                                    </app-collapse>

                                    <b-list-group v-else flush :list="system_tree" @select="(event) => {
                                        $emit('select', event);
                                    }
                                        "></b-list-group>
                                </vue-perfect-scrollbar>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </b-col>
            </b-row>

            <b-sidebar right shadow lazy bg-variant="white" v-model="sidebarVisible">
                <div class="px-2">
                    <h3>{{ modelInfo.title }}</h3>

                    {{ modelInfo.equip_meter_code }}

                    <hr />

                    <img src="./components/data/img.jpg" style="max-height: 10rem;" alt="一个具体的模型图">
                    
                    <hr />

                    <div tag="article">
                        <h4>状态参数</h4>
                        <div style="display: flex; justify-content: space-between">
                            <b-card-text>
                                故障报警
                            </b-card-text>
                            <b-card-text>
                                正常
                            </b-card-text>
                        </div>
                        <div style="display: flex; justify-content: space-between">
                            <b-card-text>
                                手/自动状态
                            </b-card-text>
                            <b-card-text>
                                自动
                            </b-card-text>
                        </div>
                        <div style="display: flex; justify-content: space-between">
                            <b-card-text>
                                运行状态
                            </b-card-text>
                            <b-card-text>
                                运行
                            </b-card-text>
                        </div>
                    </div>

                    <hr />

                    <div tag="article">
                        <h4>状态参数</h4>
                        <div style="display: flex; justify-content: space-between">
                            <b-card-text>
                                启/停控制
                            </b-card-text>
                            <b-card-text>
                                <b-form-checkbox v-model="checked" name="check-button" switch>
                                    停止
                                </b-form-checkbox>
                            </b-card-text>
                        </div>
                        <div style="display: flex; justify-content: space-between">
                            <b-card-text>
                                <b-form-checkbox v-model="checked" name="check-button" switch>
                                    强制
                                </b-form-checkbox>
                            </b-card-text>
                            <b-card-text>
                                <b-button variant="primary">设定</b-button>
                            </b-card-text>
                        </div>
                    </div>
                </div>
            </b-sidebar>
        </div>
        <!-- 右侧导航 -->
        <div class="position-absolute" style="right: 2rem; top: 6rem">
          <b-button-group vertical>
            <b-button stacked size="sm" v-for="(item, index) in add_system_list" :key="index" variant="gradient-primary" class="mb-50"
                      @click="jumpToUrl(item)">
              <div class="mb-50" style="font-size: 1.2rem"><b-icon :icon="item.icon" /></div>
              {{ item.name }}
            </b-button>
          </b-button-group>
        </div>
    </section>
</template>

<style scoped lang="scss">
.scrollable-list {
    max-height: 500px;
    /* 设置最大高度 */
    overflow-y: auto;
    /* 当内容超出时显示滚动条 */
}
</style>
